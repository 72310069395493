import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const about = () => (
  <Layout>
    <Seo title="About this" />
    <h2>About</h2>
    <p>
      I made this blog to keep track of the ideas I have.
    </p>
  </Layout>
);

export default about
